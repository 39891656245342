body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.2;
  background-color: #28282F;
  color: white; }

h1 {
  font-size: 2rem; }
  h1 span {
    font-size: 1.5rem; }

header {
  background-color: #28282F;
  box-shadow: 0px 3px 15px #000000;
  position: relative;
  z-index: 2; }
  header .events-link {
    font-size: 1.5em;
    color: #FFFFFF;
    font-weight: 300;
    transform: translateY(100%);
    display: inline-block; }
    header .events-link:hover {
      text-decoration: none; }

.bg-black {
  background-color: #000000; }

#movie-info-section {
  padding-top: 30px; }
  #movie-info-section .more {
    line-height: 16px; }
    #movie-info-section .more.hide {
      display: none; }
    #movie-info-section .more span:hover {
      cursor: pointer; }
  #movie-info-section #see_more {
    margin-left: -15px;
    transform: translateY(100%); }
  #movie-info-section #info {
    height: 41px;
    margin-bottom: 5px; }
  #movie-info-section #tickets-container .col-12 {
    max-width: 290px; }
  #movie-info-section .ticket {
    background: url("../images/bilet_245x122.png") #FFFFFF 0% 0% no-repeat padding-box;
    height: 142px;
    width: 255px;
    background-size: cover;
    position: relative;
    z-index: 1;
    box-shadow: 0px 3px 15px #000000; }
    #movie-info-section .ticket:hover {
      cursor: pointer; }
    #movie-info-section .ticket > div {
      padding: 20px 5px 0; }
      #movie-info-section .ticket > div > div {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 3px; }
      #movie-info-section .ticket > div label {
        color: #656565;
        font-size: 10px;
        line-height: 11px;
        font-weight: lighter;
        font-style: italic;
        -ms-flex: 0 0 30px;
        flex: 0 0 30px; }
      #movie-info-section .ticket > div span {
        color: #000000;
        font: 400 12px/14px 'Roboto', sans-serif; }
      #movie-info-section .ticket > div > div:first-of-type {
        margin-bottom: 5px; }
        #movie-info-section .ticket > div > div:first-of-type span {
          font-size: 17px;
          line-height: 14px;
          font-weight: 700; }
          #movie-info-section .ticket > div > div:first-of-type span span {
            font-size: 0.7rem;
            line-height: 1.2rem; }
    #movie-info-section .ticket .price {
      position: absolute;
      bottom: 14px;
      right: 5px;
      color: #FFFFFF;
      font-weight: 700;
      display: block;
      width: auto; }
      #movie-info-section .ticket .price label {
        color: #FFFFFF;
        display: block;
        flex: none; }
      #movie-info-section .ticket .price span {
        display: block;
        font-size: 25px;
        line-height: 19px;
        color: #FFFFFF;
        font-weight: 700; }
  #movie-info-section .button-buy {
    padding: 0 10px; }
    #movie-info-section .button-buy div {
      font-weight: 300;
      line-height: 28px;
      font-size: 0.9rem; }
      #movie-info-section .button-buy div:hover {
        cursor: pointer; }
      #movie-info-section .button-buy div:first-of-type {
        background-color: #7A2D91;
        border-bottom-left-radius: 10px; }
      #movie-info-section .button-buy div:last-of-type {
        background-color: #707070;
        border-bottom-right-radius: 10px; }
  #movie-info-section .ticket-overlay {
    position: absolute;
    height: 142px;
    width: 255px;
    top: 0;
    z-index: 1;
    display: none; }
    #movie-info-section .ticket-overlay div {
      position: relative; }
      #movie-info-section .ticket-overlay div:hover {
        cursor: pointer; }
      #movie-info-section .ticket-overlay div span {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        white-space: nowrap; }
    #movie-info-section .ticket-overlay .ccard {
      background-color: rgba(162, 63, 151, 0.902); }
    #movie-info-section .ticket-overlay .epay {
      background-color: rgba(112, 112, 112, 0.902); }

#poster-container .col-half {
  box-sizing: border-box;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative; }
  #poster-container .col-half:first-of-type {
    padding-left: 15px; }
  #poster-container .col-half:last-of-type {
    padding-right: 15px; }

#poster-container #poster-img {
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 15px #000000; }

#poster-container #buy-ticket {
  background-color: #197E19;
  border-bottom-left-radius: .25rem; }
  #poster-container #buy-ticket:hover {
    cursor: pointer; }

#poster-container #watch {
  background-color: #224EAB;
  border-bottom-right-radius: .25rem; }

#poster-container #buy-ticket a, #poster-container #watch a {
  display: inline-block;
  color: #FFFFFF;
  width: 100%; }
  #poster-container #buy-ticket a:hover, #poster-container #watch a:hover {
    text-decoration: none; }

.list {
  font-size: 1.5rem; }
  .list h3 {
    font-size: 2rem; }
    .list h3 span {
      font-size: 1.5rem; }
  .list label {
    font-weight: 300;
    font-style: italic; }

.link-button {
  display: inline-block;
  padding: 10px;
  color: #28282F;
  text-align: center;
  border-radius: 10px;
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.75;
  font-weight: 500;
  font-size: 1rem; }
  .link-button:hover {
    text-decoration: none;
    color: #28282F; }

@media (max-width: 380px) {
  #movie-info-section .ticket .price span {
    font-size: 24px; } }

@media (max-width: 440px) {
  header .events-link {
    font-size: 1rem; } }

@media (max-width: 576px) {
  .list {
    font-size: 1rem; }
    .list h3 {
      font-size: 1.7rem; } }

@media (max-width: 768px) {
  #movie-info-section #tickets-container .col-12 {
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 992px) {
  #poster-container {
    top: 0;
    left: 50%;
    transform: translatex(-50%);
    position: absolute; }
  #conatiner-space-holder {
    height: 280px; }
  #movie-info-section {
    padding-top: 90px; } }

@media (max-width: 991px) {
  #poster-container {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; }
  .list {
    font-size: 1rem; }
    .list h3 {
      font-size: 1.7rem; }
  .bg-black .row div:nth-of-type(2n) .row div:first-of-type {
    padding-right: 0; }
  #movie-info-section #see_more {
    transform: none;
    margin: 0;
    text-align: right !important; } }

@media (min-width: 1200px) {
  #conatiner-space-holder {
    height: 330px; }
  #movie-info-section {
    padding-top: 100px; } }
